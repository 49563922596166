import React from "react";
import './footer.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import KlarLogoBw from './klar-logo-bw';
import Divider from '@material-ui/core/Divider';

const FooterSection = () => (
 
  <Grid container spacing={1} className="footer-section">   
      <Grid item xs={12} sm={6}>
        <Typography variant="overline"> This website was built in-house at KLAR Solutions Inc. </Typography>
        <br />
          <Typography variant="overline">
            © {new Date().getFullYear()}
          </Typography>
          <Grid container>
            <Grid item className="klar-logo-bw" >
              <KlarLogoBw className="klar-logo-bw" />
            </Grid>
            <Grid item>
             <Typography display="inline" variant="h3" className="footer-brand klar">KLAR</Typography>
             <Typography display="inline" variant="h6" className="footer-brand"> Solutions Inc.</Typography>
            </Grid>
          </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className="site-list">
        <Typography variant="overline">Site</Typography>
        <Link to="/"><Typography variant="subtitle2"> Home </Typography></Link>
        <Link to="/how-we-work"><Typography variant="subtitle2"> How we work </Typography> </Link>
        <Link to="/clients-and-projects"><Typography variant="subtitle2"> Clients and Projects </Typography> </Link>
        <Link to="/contact-us"><Typography variant="subtitle2"> Contact Us </Typography> </Link>
      </Grid>
  </Grid>
)

export default FooterSection;



