import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import './app-bar.css'
import KlarLogo from './klar-logo'
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dehaze from '@material-ui/icons/Dehaze';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function ElevateAppBar(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className="top-nav-bar">
          <Toolbar>
            <Link to='/'>
              <Grid container alignItems='baseline' className="brand-section">
                <Grid item className="klar-logo">
                  <KlarLogo />
                </Grid>
              <Typography variant="h3" className="brand-title" display="inline">KLAR </Typography>
              <Typography variant="h5" display="inline" className="brand-title">
               Solutions Inc.</Typography>
              </Grid>
            </Link>

            <div className="nav-link-row">
              <Link to="/" className="nav-bar-links">Home</Link>   
              <Link to="/how-we-work/" className="nav-bar-links">How We Work</Link>
              <Link to="/clients-and-projects/" className="nav-bar-links">Clients and projects</Link>
              <Link to="/contact-us/" className="nav-bar-links">Contact Us</Link>
            </div> 
            <div className="mobile-menu-section">
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Dehaze className="mobile-menu" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="simple-menu"
                >
                <MenuItem onClick={handleClose}> <Link to="/" className="nav-bar-links">Home</Link></MenuItem>
                <MenuItem onClick={handleClose}> <Link to="/how-we-work/" className="nav-bar-links">How We Work</Link></MenuItem>
                <MenuItem onClick={handleClose}> <Link to="/clients-and-projects/" className="nav-bar-links">Clients and projects</Link></MenuItem>
                <MenuItem onClick={handleClose}> <Link to="/contact-us/" className="nav-bar-links">Contact Us</Link></MenuItem>
              </Menu>
            </div>   
          </Toolbar>

        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}