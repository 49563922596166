import React from 'react';
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import './contact-us-button.css';

const ContactUsButton = (props) => (
	<Link to='/contact-us'>
		<Button variant="contained" className="contact-us-button">
			{ props.buttonText }
		</Button>
	</Link> 

	)
export default ContactUsButton
